import { css } from "@emotion/css";
import { RouteComponentProps } from "@reach/router";
import { FC, useState, useEffect } from "react";

export const New: FC<RouteComponentProps> = () => {
  const [title, setTitle] = useState<string>("");
  const [url, setUrl] = useState<string>(
    "https://iterateas.whereby.com/warp_zone"
  );
  const [speaker, setSpeaker] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [date, setDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );

  useEffect(() => {
    console.log("date", date);
  }, [date]);

  const handleDateSelect = (d: string) => {
    const date = new Date(d);

    if (date.getDay() !== 3) {
      alert("Du kan kun velge onsdager!!!");
    } else {
      setDate(d);
    }
  };

  return (
    <div
      className={css`
        padding: 16px;
      `}
    >
      <h1>Registerer en ny mens vi venter</h1>
      <div>
        <form
          className={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <label>
            Tittel
            <input
              type="text"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
            />
          </label>
          <label>
            URL
            <input
              type="text"
              value={url}
              onChange={(event) => setUrl(event.target.value)}
            />
          </label>
          <label>
            Foredragsholder
            <input
              type="text"
              value={speaker}
              onChange={(event) => setSpeaker(event.target.value)}
            />
          </label>
          <label>
            Besrivelse
            <input
              type="textarea"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </label>
          <label>
            Dato
            <input
              type="date"
              value={date}
              onChange={(event) => handleDateSelect(event.target.value)}
            />
          </label>
        </form>
      </div>
    </div>
  );
};
