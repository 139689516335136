import { css, Global } from "@emotion/react";
import { normalize } from "polished";

export const globalStyles = (
  <Global
    styles={css`
      ${normalize()};

      *,
      *:before,
      *:after {
        box-sizing: border-box;
      }

      body {
        height: 100%;
        width: 100%;
        margin: 0;
        background-color: #ebeee3;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
          "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
          "Helvetica Neue", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    `}
  />
);
