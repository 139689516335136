import { Router } from "@reach/router";
import { Home } from "./pages/Home";
import { New } from "./pages/New";
import { Talk } from "./pages/Talk";
import { FC } from "react";
import { globalStyles } from "./styles/styles";

export const App: FC = () => {
  return (
    <>
      {globalStyles}
      <div className="App">
        <Router>
          <Home path="/" />
          <New path="/new" />
          <Talk path="/:id" />
        </Router>
      </div>
    </>
  );
};
