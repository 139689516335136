import { FC } from "react";
import { RouteComponentProps, Link } from "@reach/router";
import { css } from "@emotion/css";

export const Home: FC<RouteComponentProps> = () => {
  return (
    <div>
      <nav
        className={css`
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 24px;
        `}
      >
        Mens vi venter
        <Link to="/">Home</Link>
        <Link to="/new">New</Link>
      </nav>
      <div></div>
    </div>
  );
};
